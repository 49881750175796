import React from 'react'

function Header() {
  return (
    <div>
    <header>
        <img src='images/profile.jpg' className="profile" alt="profile"/>        
        <div className='headers'>
            <h1>Anna Harbour</h1>

            <h2>Software Developer</h2>
        </div>
    </header>

        
    </div>

)
}

export default Header